import type { ProviderGetImage } from "@nuxt/image";
import { withBase, withoutBase } from "ufo";

// @see
// * https://image.nuxtjs.org/advanced/custom-provider/

export const getImage: ProviderGetImage = (src, { baseUrl = "http://127.0.0.1:1337/uploads" } = {}) => {
  const relativeImagePath = withoutBase(src, "/uploads");

  return {
    url: withBase(relativeImagePath, baseUrl),
  };
};

export const validateDomains = true;
