import type { ProviderGetImage } from "@nuxt/image";
import { withBase, withoutBase, withoutLeadingSlash } from "ufo";

// @see
// * https://image.nuxtjs.org/advanced/custom-provider/
// * https://docs.strapi.io/dev-docs/plugins/upload#responsive-images

export const getImage: ProviderGetImage = (src, { baseUrl = "http://127.0.0.1:1337/uploads", modifiers } = {}) => {
  const breakpoint = modifiers?.breakpoint ?? "";
  const relativeImagePath = withoutBase(src, "/uploads");

  if (!breakpoint) {
    return {
      url: withBase(relativeImagePath, baseUrl),
    };
  }

  return {
    url: withBase(`${breakpoint}_${withoutLeadingSlash(relativeImagePath)}`, baseUrl),
  };
};

export const validateDomains = true;
